<template>
  <v-container>
    <div class="mb-5 d-flex justify-end">
      <v-dialog v-model="dialog" width="500" persistent scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            :disabled="loading"
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-plus-thick</v-icon>
            Adicionar
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary justify-start white--text">
            {{
              isCriandoCalendario
                ? "Cadastro Calendário"
                : "Atualizar Calendário"
            }}
          </v-card-title>
          <v-card-text>
            <v-form v-if="dialog" ref="calendarioForm">
              <v-select
                v-model="calendarioEditado.situacaoProcesso.codigo"
                placeholder="Situação Processo"
                label="Situação Processo *"
                :items="situacoes"
                item-text="nome"
                item-value="codigo"
                class="pa-4 mt-4"
                :rules="[campoObrigatorioRule]"
              >
              </v-select>
              <v-row class="mr-1 ml-1">
                <v-col cols="12" sm="6">
                  <v-menu
                    ref="startMenu"
                    :close-on-content-click="false"
                    :return-value.sync="calendarioEditado.inicio"
                    offset-y
                    min-width="200px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(calendarioEditado.inicio)"
                        label="Data Inicio *"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          campoObrigatorioRule,
                          dataAnterior(
                            calendarioEditado.inicio,
                            calendarioEditado.fim
                          )
                        ]"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <vc-date-picker
                        color="green"
                        mode="dateTime"
                        is24hr
                        locale="pt-BR"
                        :modelConfig="{ timeAdjust: '12:00:00' }"
                        v-model="calendarioEditado.inicio"
                        no-title
                        scrollable
                      ></vc-date-picker>
                      <v-spacer></v-spacer>
                      <v-card-actions class="justify-lg-space-around">
                        <v-btn
                          color="error"
                          @click="$refs.startMenu.isActive = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="
                            $refs.startMenu.save(calendarioEditado.inicio)
                          "
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-menu
                    ref="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="calendarioEditado.fim"
                    offset-y
                    min-width="200px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(calendarioEditado.fim)"
                        label="Data Fim *"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          campoObrigatorioRule,
                          dataPosterior(
                            calendarioEditado.inicio,
                            calendarioEditado.fim
                          )
                        ]"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <vc-date-picker
                        color="green"
                        mode="dateTime"
                        is24hr
                        locale="pt-BR"
                        :modelConfig="{ timeAdjust: '12:00:00' }"
                        v-model="calendarioEditado.fim"
                        no-title
                        scrollable
                      >
                      </vc-date-picker>
                      <v-spacer></v-spacer>
                      <v-card-actions class="justify-lg-space-around">
                        <v-btn
                          color="error"
                          @click="$refs.endMenu.isActive = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="$refs.endMenu.save(calendarioEditado.fim)"
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions class="pa-4">
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelarEdicaoCalendario">
                Cancelar
              </v-btn>
              <v-btn
                :disabled="loading"
                :loading="loading"
                color="primary"
                @click="salvarCalendario"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-card v-if="calendarios.length && carregou">
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Calendário
                </th>
                <th class="text-left">
                  Data Inicio
                </th>
                <th class="text-left">
                  Data Fim
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="calendario in calendarios" :key="calendario.oid">
                <td>{{ calendario.situacaoProcesso.nome }}</td>
                <td>{{ formatDate(calendario.inicio) }}</td>
                <td>{{ formatDate(calendario.fim) }}</td>
                <td class="text-end">
                  <v-dialog max-width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        :disabled="loading"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        color="error"
                        icon
                        x-small
                      >
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-card-title class="primary justify-start white--text"
                          >Confirmar Exclusão
                        </v-card-title>
                        <v-card-text
                          style="font-size: 15pt; text-align: center"
                          class="mt-5 font-weight-bold"
                        >
                          Deseja Realmente Excluir o Calendário? Esta ação não
                          poderá ser desfeita!
                        </v-card-text>
                        <v-card-actions class="justify-center">
                          <v-btn
                            color="primary"
                            @click="
                              deletarCalendario(calendario.oid);
                              dialog.value = false;
                            "
                            >Confirmar
                          </v-btn>
                          <v-btn color="error" @click="dialog.value = false"
                            >Cancelar
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <v-btn
                    class="mx-2"
                    color="accent"
                    icon
                    x-small
                    @click="editarCalendario(calendario)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-sheet v-else-if="!calendarios.length && carregou">
      <v-row justify="center">
        <v-alert type="info" dense outlined border="left">
          Não existem calendários
        </v-alert>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import calendarioProcessoService from "@/services/calendarioProcesso.service";
import situacaoProcessoService from "@/services/situacaoProcesso.service";
import {mapActions, mapState} from "vuex";
import rules from "@/commons/rules";
import luxon from "@/core/luxon";

export default {
  name: "Calendario",

  computed: {
    ...mapState("processoSelecionado", ["processoSelecionado"]),

    isCriandoCalendario() {
      return !this.calendarioEditado.oid;
    }
  },

  async created() {
    let loader = this.$loading.show();

    await calendarioProcessoService
      .recuperarCalendariosProcesso(this.processoSelecionado.oid)
      .then(response => {
        this.calendarios = response.data;
      })
      .catch(() => {});

    await situacaoProcessoService
      .recuperarSituacoesProcesso()
      .then(response => {
        this.situacoes = response.data;
      })
      .catch(() => {});

    this.carregou = true;
    loader.hide();
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),
    ...mapActions("processoSelecionado", ["atualizarProcessoSelecionado"]),
    ...rules,

    formatDate(data) {
      if (data === undefined){
        return "";
      }
      return luxon.dataConverter(data).toFormat("dd/LL/yyyy HH:mm");
    },

    editarCalendario(calendario) {
      this.calendarioEditado = { ...calendario };
      this.dialog = true;
    },

    cancelarEdicaoCalendario() {
      this.dialog = false;
      this.calendarioEditado = { situacaoProcesso: {} };
    },

    async salvarCalendario() {
      if (this.$refs.calendarioForm.validate()) {
        this.loading = true;
        let loader = this.$loading.show();

        if (this.isCriandoCalendario) {
          await this.criarCalendario();
        } else {
          await this.atualizarCalendario();
        }

        loader.hide();
        this.loading = false;
      }
    },

    async criarCalendario() {
      await calendarioProcessoService
        .criarCalendarioProcesso(
          this.processoSelecionado.oid,
          this.calendarioEditado
        )
        .then(async () => {
          await calendarioProcessoService
            .recuperarCalendariosProcesso(this.processoSelecionado.oid)
            .then(async response => {
              this.calendarios = response.data;
              this.dialog = false;

              await this.atualizarProcessoSelecionado(this.processoSelecionado.oid);

              this.cancelarEdicaoCalendario();
            });
          this.exibirAviso({
            mensagem: "Calendario criado com sucesso!",
            tipo: "success"
          });
        })
        .catch(erro => {
          let mensagem = "Erro ao salvar calendário";
          if (erro.response.status === 409) {
            mensagem += ": " + erro.response.data.msg;
          }
          this.exibirAviso({
            mensagem: mensagem,
            tipo: "error"
          });
        });
    },

    async atualizarCalendario() {
      await calendarioProcessoService
        .atualizarCalendarioProcesso(this.calendarioEditado)
        .then(async () => {
          await calendarioProcessoService
            .recuperarCalendariosProcesso(this.processoSelecionado.oid)
            .then(async response => {
              this.calendarios = response.data;
              this.dialog = false;

              await this.atualizarProcessoSelecionado(this.processoSelecionado.oid);

              this.cancelarEdicaoCalendario();
            });
          this.exibirAviso({
            mensagem: "Calendario salvo com sucesso!",
            tipo: "success"
          });
        })
        .catch(erro => {
          let mensagem = "Erro ao salvar calendário";
            if (erro.response.status === 409) {
              mensagem += ": " + erro.response.data.msg;
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
        });
    },

    async deletarCalendario(calendarioOid) {
      let loader = this.$loading.show();

      await calendarioProcessoService
        .deletarCalendarioProcesso(calendarioOid)
        .then(async () => {
          await calendarioProcessoService
            .recuperarCalendariosProcesso(this.processoSelecionado.oid)
            .then(response => {
              this.calendarios = response.data;
            });
          loader.hide();
          this.exibirAviso({
            mensagem: "Calendario excluido com sucesso!",
            tipo: "success"
          });
          await this.atualizarProcessoSelecionado(this.processoSelecionado.oid);
        })
        .catch(() => {
          loader.hide();
          this.exibirAviso({
            mensagem: "Erro ao excluir calendario!",
            tipo: "error"
          });
        });
    }
  },

  data() {
    return {
      calendarios: [],
      calendarioEditado: { situacaoProcesso: {} },
      loading: false,
      dialog: false,
      carregou: false,
      situacoes: []
    };
  }
};
</script>

<style scoped></style>
