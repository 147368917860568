var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"mb-5 d-flex justify-end"},[_c('v-dialog',{attrs:{"width":"500","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","disabled":_vm.loading,"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary justify-start white--text"},[_vm._v(" "+_vm._s(_vm.isCriandoCalendario ? "Cadastro Calendário" : "Atualizar Calendário")+" ")]),_c('v-card-text',[(_vm.dialog)?_c('v-form',{ref:"calendarioForm"},[_c('v-select',{staticClass:"pa-4 mt-4",attrs:{"placeholder":"Situação Processo","label":"Situação Processo *","items":_vm.situacoes,"item-text":"nome","item-value":"codigo","rules":[_vm.campoObrigatorioRule]},model:{value:(_vm.calendarioEditado.situacaoProcesso.codigo),callback:function ($$v) {_vm.$set(_vm.calendarioEditado.situacaoProcesso, "codigo", $$v)},expression:"calendarioEditado.situacaoProcesso.codigo"}}),_c('v-row',{staticClass:"mr-1 ml-1"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"startMenu",attrs:{"close-on-content-click":false,"return-value":_vm.calendarioEditado.inicio,"offset-y":"","min-width":"200px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.calendarioEditado, "inicio", $event)},"update:return-value":function($event){return _vm.$set(_vm.calendarioEditado, "inicio", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.calendarioEditado.inicio),"label":"Data Inicio *","prepend-inner-icon":"mdi-calendar","readonly":"","rules":[
                        _vm.campoObrigatorioRule,
                        _vm.dataAnterior(
                          _vm.calendarioEditado.inicio,
                          _vm.calendarioEditado.fim
                        )
                      ]}},'v-text-field',attrs,false),on))]}}],null,false,3654223288)},[_c('v-card',[_c('vc-date-picker',{attrs:{"color":"green","mode":"dateTime","is24hr":"","locale":"pt-BR","modelConfig":{ timeAdjust: '12:00:00' },"no-title":"","scrollable":""},model:{value:(_vm.calendarioEditado.inicio),callback:function ($$v) {_vm.$set(_vm.calendarioEditado, "inicio", $$v)},expression:"calendarioEditado.inicio"}}),_c('v-spacer'),_c('v-card-actions',{staticClass:"justify-lg-space-around"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.$refs.startMenu.isActive = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.startMenu.save(_vm.calendarioEditado.inicio)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"endMenu",attrs:{"close-on-content-click":false,"return-value":_vm.calendarioEditado.fim,"offset-y":"","min-width":"200px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.calendarioEditado, "fim", $event)},"update:return-value":function($event){return _vm.$set(_vm.calendarioEditado, "fim", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate(_vm.calendarioEditado.fim),"label":"Data Fim *","prepend-inner-icon":"mdi-calendar","readonly":"","rules":[
                        _vm.campoObrigatorioRule,
                        _vm.dataPosterior(
                          _vm.calendarioEditado.inicio,
                          _vm.calendarioEditado.fim
                        )
                      ]}},'v-text-field',attrs,false),on))]}}],null,false,2477970107)},[_c('v-card',[_c('vc-date-picker',{attrs:{"color":"green","mode":"dateTime","is24hr":"","locale":"pt-BR","modelConfig":{ timeAdjust: '12:00:00' },"no-title":"","scrollable":""},model:{value:(_vm.calendarioEditado.fim),callback:function ($$v) {_vm.$set(_vm.calendarioEditado, "fim", $$v)},expression:"calendarioEditado.fim"}}),_c('v-spacer'),_c('v-card-actions',{staticClass:"justify-lg-space-around"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.$refs.endMenu.isActive = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.endMenu.save(_vm.calendarioEditado.fim)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"pa-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancelarEdicaoCalendario}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":_vm.salvarCalendario}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1),(_vm.calendarios.length && _vm.carregou)?_c('v-card',[_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Calendário ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Data Inicio ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Data Fim ")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.calendarios),function(calendario){return _c('tr',{key:calendario.oid},[_c('td',[_vm._v(_vm._s(calendario.situacaoProcesso.nome))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(calendario.inicio)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(calendario.fim)))]),_c('td',{staticClass:"text-end"},[_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","disabled":_vm.loading,"color":"error","icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"primary justify-start white--text"},[_vm._v("Confirmar Exclusão ")]),_c('v-card-text',{staticClass:"mt-5 font-weight-bold",staticStyle:{"font-size":"15pt","text-align":"center"}},[_vm._v(" Deseja Realmente Excluir o Calendário? Esta ação não poderá ser desfeita! ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.deletarCalendario(calendario.oid);
                            dialog.value = false;}}},[_vm._v("Confirmar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar ")])],1)],1)]}}],null,true)}),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"accent","icon":"","x-small":""},on:{"click":function($event){return _vm.editarCalendario(calendario)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)])}),0)]},proxy:true}],null,false,3184475283)})],1)],1):(!_vm.calendarios.length && _vm.carregou)?_c('v-sheet',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-alert',{attrs:{"type":"info","dense":"","outlined":"","border":"left"}},[_vm._v(" Não existem calendários ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }