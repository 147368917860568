import Vue from 'vue'
import luxon from "@/core/luxon";

export default {
    recuperarProcessosPublicados() {
        return Vue.axios
            .get("calendarioProcesso/calendarios")
    },

    recuperarCalendariosProcesso(processoOid){
        return Vue.axios
            .get("calendarioProcesso/processo/" + processoOid)
    },

    atualizarCalendarioProcesso(calendario){
        return Vue.axios
            .put("calendarioProcesso/" + calendario.oid, {
                oid: calendario.oid,
                inicio: this.dataConverter(calendario.inicio),
                fim: this.dataConverter(calendario.fim),
                situacaoProcesso: calendario.situacaoProcesso
            })
    },

    criarCalendarioProcesso(processoOid, calendario){
        return Vue.axios
            .post("calendarioProcesso/" + processoOid,{
                inicio: this.dataConverter(calendario.inicio),
                fim: this.dataConverter(calendario.fim),
                situacaoProcesso: calendario.situacaoProcesso
            })
    },

    deletarCalendarioProcesso(calendarioOid){
        return Vue.axios
            .delete("calendarioProcesso/" + calendarioOid)
    },

    dataConverter(data){
        return luxon.dataConverter(data).toFormat("yyyy-LL-dd TT");
    }
}
